import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';
import Home from './containers/Home';
import Admin from './containers/Admin';
import VirtualSession from './containers/VirtualSession';
import NotFound from './containers/NotFound';

export default ({ appProps }) => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} appProps={appProps} />
      <Route exact path="/virtual-session" component={VirtualSession} appProps={appProps} />
      <Route exact path="/admin" component={Admin} appProps={appProps} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
)