import React from "react";
import Routes from "./Routes";
import preval from 'preval.macro'
import Moment from "react-moment";
import 'moment-timezone';

export default props => (
  <div>
    <header>
      <div className="pb-5 pt-5 container-fluid bg-light header-bg text-white text-center">
        <div className="row">
          <div className="col-5 text-right">
            <a href="/"><img className="logo" alt="Larkin' The Woods" src="images/lark_logo.svg"/></a>
          </div>
          <div className="col-7 text-left">
            <h1>Larkin' the <br/>Woods</h1>
            <p><i>South London ceilidh and folk band</i></p>
          </div>
        </div>
      </div>
    </header>

    <main className="bg-light p-4 p-md-5">
      <Routes />
    </main>
    
    <footer>
      <div className="text-center mt-1 mb-2">
        <h1 className="pt-3 pb-3 text-light">Find us on <a title="Facebook" href="https://www.facebook.com/larkinthewoods"><i className="text-white fa fa-facebook-square"></i></a></h1>
        <small>
          <div className="text-light">&copy; Larkin' the Woods <Moment format="YYYY">{new Date()}</Moment></div>
          <div>Build Date:<Moment format="MMMM Do YYYY, HH:mm:ss">{preval`module.exports = new Date().toLocaleString();`}</Moment></div>
        </small>
      </div>
    </footer>
  </div>
)