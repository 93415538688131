import React, {useEffect, useState} from "react";
import Moment from "react-moment"
import axios from 'axios'; 

export default props => {  
    const [events, setEvents] = useState([]);

    useEffect (() => {
        async function fetchData() {
            await axios.get('/data/events.json')
                .then(res => 
                    setEvents(
                        res.data.sort((a,b) => (a.start_time > b.start_time))
                    ))
        }
        fetchData();
    }, []);

    const EventList = () => events.map((event,index) => (
        <div key={index} className="card card-body shadow p-0 mb-4">
            <div className="row p-0 m-0">
                <div className="p-4 col-md-3 order-md-1 order-2">
                    <h5 className="text-primary d-none"><i className="fa fa-calendar-alt"></i></h5>
                    <div className="float-right float-md-none text-right text-md-left">
                        <small>
                            <address>
                                <b className="text-primary"><Moment format="MMMM Do YYYY">{event.start_time}</Moment></b><br/>
                                <Moment format="HH:mm">{event.start_time}</Moment> - <Moment format="HH:mm">{event.end_time}</Moment>
                            </address>
                        </small>
                    </div>
                    <div key={index}>
                        <small>
                            <address>
                                <a className="text-primary" href={event.maplink}><strong>{event.place.name}</strong></a><br/>
                                {event.place.location.street}<br/>
                                {event.place.location.city}<br/>
                                {event.place.location.zip}
                            </address>
                        </small>
                    </div>
                </div>
                <div className="col-md-9 p-4 order-md-2 order-1">
                    <a href={"https://www.facebook.com/events/" + event.id}><h5>{event.name}</h5></a>
                    <div>
                        {
                            event.description.split("\n\n").map((line) => <p>{line}</p>)
                        }
                    </div>
                    <hr className="d-block d-md-none"/>
                </div>
            </div>
        </div>
    ))
                     
    return events.length==0 ? (
        <div className="alert bg-white shadow">Sorry, no events scheduled at the moment, please come back soon.</div>
    ) : <EventList/>

}

