import React, { useState, useEffect } from 'react'
import axios from 'axios'; 

export default props => {
    const [tunes, setTunes] = useState([]);

    useEffect (() => {
        async function fetchData() {
            await axios.get('/data/virtual-session/tunes.json')
                .then(res => setTunes(res.data))
        }
        fetchData();
    }, []);

    return (
        <div>
            <h1>Virtual Session</h1>
            <p>List of tunes in progress with latest splices:</p>
            <table className="table  bg-white shadow">
                <tbody>
                    {
                        tunes.map((tune,index) => (
                            <tr key={index}>
                                <td>{tune.title}</td>
                                <td>{tune.lead=="" ? "-none-" : <a href={tune.lead}>Lead</a>}</td>
                                <td>{tune.final=="" ? "-none-" : <a href={tune.final}>Latest Splice</a>}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            
            <hr className="mt-5 mb-5 bg-primary"/>

            <h2>Instructions</h2>
            <p>Here are some guidelines to help virtual sessions run smoothly and help people enjoy it.</p>

            <div className="alert bg-white shadow">
                <h4 class="alert-heading">What is a Virtual Session?</h4>
                <p>Essentially a series of individual recordings (with or without video - but better with video) playing along to a common tune / tune set. One person records themselves (the “tune starter”) and circulates the recording for others to play along to and to record themselves (recording yourself is optional if you just want to play along, but not feature in the video). The separate recordings are then spliced together with clever camera trickery to form a single “session video”.</p>
            </div>

            <div className="alert bg-white shadow">
                <h4 class="alert-heading">What do I need to do this?</h4>
                <p>Most importantly the ability to hear the “tune starter”. If want to contribute and record yourself, will need the ability to playback the tune via headphones and to be able to record yourself. There are many ways of doing this, but most revolve around either using 2x devices or a piece of recording software like Garageband or Logic, Cubase etc.</p>

                <ul>
                    <li>
                        <h5>Using 2x devices (mobile phone/tablet/laptop/pc any combination). </h5>
                        <p>Simply playback the recording on one device (plugging in your headphones), then recording on another device. The recording device can be as simple as a phone/tablet/laptop and using its internal microphone, or using a separate audio interface + microphone. It really depends what you have available. This isn’t a professional recording exercise, its about the inclusiveness and fun!</p>
                    </li>
                    <li>
                        <h5>Using a single device with a DAW (digital audio workstation) - Garageband, Logic, Cubase etc </h5>
                        <p>Garageband is free for Mac/iOS and is perfect for this type of thing. You simply import the “tune starters” recording and playback and record at the same time. </p>
                        <p>It should be noted that this approach requires quite a bit of skill and understanding on recording.</p>
                        <p>Examples of free software (PC and Mac) https://thehomerecordings.com/free-daw/</p>
                    </li>
                </ul>
            </div> 

            <div className="alert bg-white shadow">
                <h4 class="alert-heading">How do I get the “tune starter” recording?</h4>
                <p>They will upload the video onto WhatsApp and in the table above (lead link)</p>
            </div>

            <div className="alert bg-white shadow">
                <h4 class="alert-heading">Where do I put my recordings?</h4>
                <p>Anywhere they can be accessed by whoever is splicing the videos. They can be uploaded into your own dropbox or google drive and shared, or (easiest) uploaded into WhatsApp - we’ve found that WhatsApp on mobile works better for uploading files.</p>
            </div>

            <div className="alert bg-white shadow">
                <h4 class="alert-heading">Any rules/guidelines?</h4>
                <p>No rules!, just good session etiquette:</p>
                <ul>
                <li>If you start a tune/set, please announce it, state the key, number of repeats, and include a few bars as an intro.</li>
                <li>Keep it steady! - wrong notes are fine (we all do that, or miss some out!), but please try and keep the tempo steady, even if it means playing a bit slower. Playing along with a metronome or click-track is helpful.</li>
                <li>Use the WhatsApp chats to share tune/set ideas to ensure there are enough people who know the tunes</li>
                <li>No virtuosity, solos etc. Keep to the tune structure / parts. However, harmonies and variation of course are very welcome!</li>
                <li>Sharing: if you record yourself and feature in the final output videos, then you are free to circulate them. There is no copywrite (trad tunes). </li>
                </ul>
            </div>

            <div className="alert bg-white shadow">
                <h4 class="alert-heading">How does this trickery work?</h4>
                <p>Videos are spliced together by me with Final Cut, and sync’d up by ear. Each video is resized to allow many recordings to fit on the page.</p>
            </div>
        </div>
    )
}