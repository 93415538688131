import React from "react";
import Events from "../components/Events";

export default props => (
    <div>
        <h1>Events</h1>
        <Events/>
    </div>
)

