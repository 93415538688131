import React, { useState, useEffect } from "react";
import moment from "moment"
import FacebookLogin from 'react-facebook-login';
import config from "../config.js";
import axios from 'axios'; 
import Events from "../components/Events";

export default props => {
    const [events, setEvents] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    
    const saveEvents = async(data) => {
        setLoggedIn(true);
        await axios.get(config.facebook.fb_url + "/larkinthewoods/events?access_token=" + data.accessToken)
        .then(res => {
            let events = [];
            res.data.data.map((event) => {
                if (!event.hasOwnProperty('place')) event.place = {
                    name:'', 
                    location:{
                        street:'',
                        city:'',
                        zip:'',
                        longitude:'',
                        latitude:''
                    }
                }

                if (!event.place.hasOwnProperty('location')) event.place.location = {
                    location:{
                        street:'',
                        city:'',
                        zip:'',
                        longitude:'',
                        latitude:''
                    }
                }

                if (!event.place.location.hasOwnProperty('street')) event.place.location.street = '';
                if (!event.place.location.hasOwnProperty('city')) event.place.location.city = '';
                if (!event.place.location.hasOwnProperty('zip')) event.place.location.zip = '';
                if (!event.place.location.hasOwnProperty('longitude')) event.place.location.longitude = '';
                if (!event.place.location.hasOwnProperty('latitude')) event.place.location.latitude = '';

                event.maplink = "https://www.google.co.uk/maps/place/" + event.place.location.latitude + "," + event.place.location.longitude;
                
                // Cater for repeating events - ie monthly's

                if (event.hasOwnProperty('event_times')) {    
                    event.event_times.map(time => {
                        let repeat = {...event};
                        repeat.start_time = time.start_time;
                        repeat.end_time = time.end_time;
                        repeat.id = time.id;
                        delete repeat.event_times;

                        // Only include future events
                        if (moment(repeat.end_time).isAfter()) events.push(repeat);
                        
                    });
                
                } else {
                    // Only include future events
                    if (moment(event.end_time).isAfter()) events.push(event);
                
                }
            });
            return events;

        })
        .then(res => {
            setEvents(res);
            if (process.env.hasOwnProperty("REACT_APP_STAGE")) {
                const options = {
                    headers: {'x-api-key':config.api.key}
                }
                axios.post(`${config.api.url}/events`, res, options).then(res => {
                    console.log(res.data);
                });
            } 
        });
    }


    return loggedIn 
        ? (
            <div>
                <div className="mb-3 progress shadow border border-secondary" style={{height:"50px"}}>
                    <div className="progress-bar progress-bar-striped text-white bg-secondary" style={{width:"100%"}}><span className="h5 strong"><b>{events.length}</b>&nbsp;events refreshed</span></div>
                </div>
                {   
                    process.env.hasOwnProperty("REACT_APP_STAGE") ? null : (
                    <div>
                        <h5 className="text-light">Events</h5>
                        <Events/>
                    </div>
                    )
                }
            </div>
        )
        :
        (
            <center>
                <FacebookLogin
                    appId={config.facebook.fb_app_id}
                    autoLoad={false}
                    disableMobileRedirect={true}
                    fields="name,email,picture"
                    callback={saveEvents}
                />
            </center>
        )

}