export default {
    facebook: {
        fb_url: "https://graph.facebook.com",
        fb_album: "561356074379553",
        fb_app_id: "958719677629172",
        fb_page_id: "395407650974397",
    },
    api: {
        key: process.env.REACT_APP_API_KEY,
        url: process.env.REACT_APP_API_URL
    }
};